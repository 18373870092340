import React from "react";
import xing_logo from "../images/xing.png";
import linkedIn_logo from "../images/linkedIn.png";

function Home() {
  return (
    <div className="home">
      <div className="subhead"></div>
      <div className="socialmedia">
        <a
          href="http://www.linkedin.com/in/kaisteuernagel"
          target="_blank"
          rel="noreferrer"
        >
          <img className="sm_logo" src={linkedIn_logo} alt="LinkedIn" />
        </a>

        <br />
        <br />

        <a
          href="http://www.xing.com/profile/Kai_Steuernagel"
          target="_blank"
          rel="noreferrer"
        >
          <img className="sm_logo" src={xing_logo} alt="Xing" />
        </a>
      </div>
      <div className="homedesc">
        <p className="largetext">
          This is a private playground where I host some of my developed tools
          and things. I do this as a hobby. You may find some of the tools
          useful. <br />
          <br />I spend some time into the EV Tools section and developed some
          calculations and graphs for the charging process. A small database of
          cars behind may give you an glimpse on the differences. If you feel,
          that some information there is rather incorrect or you are missing a
          specific model, just get in touch and maybe I'll find the time for the
          correction and addition. No guarantee though.
        </p>
        <br />
        <p className="largetext">
          Also, I have developed a repayment plan (Tilgungsplan). I kept that in
          German, my native language. This could be used i. e. to calculate your
          rate or duration to pay for your EV. Still, I am not a financial
          expert and it doesn't work 100% exact when it comes to very low
          interest rates or short durations. But it will give you an idea. No
          guarantee though. Check your banks repayment plan.
        </p>
        <p className="largetext">
          I worked a bit on the responsive design so that the pages are also
          readable in mobile phones. It might not yet be perfect, so please bare
          with me for a bit until I optimized it further.
        </p>
      </div>
      <div className="disclaimer">
        <div className="sub2head">Disclaimer</div>
        <span>
          <p className="text">
            Even when I work in the IT space, programming is not my main skill.
            I only do this to keep my hands dirty from time to time and to learn
            new things. The work involve mostly ReactJS.
          </p>
          <p className="text">
            Nothing here is really professional work, nor should it be taken as
            an example for coding.
          </p>
          <p className="text">
            If you like the tools, use them but don't ask for the source code or
            such, you would be extremely disapointed (if you understand a little
            about coding yourself).
          </p>
          <br />
          <p className="largetext">
            If you are interested in me as person, visit my profile on LinkedIn
            and XING.
          </p>
        </span>
      </div>
    </div>
  );
}

export default Home;
