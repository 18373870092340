import React, { useState } from "react";
import * as IoIcons from "react-icons/io";

// *** Function to ad leading zeros zo time or date elements
function leadingZero(element) {
  if (element < 10) return "0" + element;
  else return element;
}

// *** Function to calculate the duration for the timer in seconds from input fields
function calcDuration(minutes, seconds) {
  let duration = 120;
  if (!(minutes.value === "" || seconds.value === "")) {
    duration = parseInt(minutes) * 60 + parseInt(seconds);
  }
  return duration;
}

function buildTimerURL(duration) {
  return  "https://www.steuernagel.net/ttimer/?duration=" + duration;
}

function buildCountdownURL(epochtime) {
  return  "https://www.steuernagel.net/countdown/?datetime=" + epochtime;
}

function toDateString(d) {
  let datestr =
    d.getFullYear() +
    "-" +
    leadingZero(d.getMonth() + 1) +
    "-" +
    leadingZero(d.getDay());
  return datestr;
}

function toTimeString(d, addMin) {
  let timestr =
    leadingZero(d.getHours()) + ":" + leadingZero(d.getMinutes() + addMin);
  return timestr;
}

function toDateFormat(d, t) {
  let ds = d + "T" + t + ":00";
  console.log(d, t, ds);
  // from  format like '1995-12-17T03:24:00' to epoch time ..
  return new Date(ds).valueOf();
}

function Timer() {
  let date = new Date();
  const [timerMin, setTimerMin] = useState(2);
  const [timerSec, setTimerSec] = useState(0);
  const [countdownDate, setCountdownDate] = useState(toDateString(date));
  const [countdownTime, setCountdownTime] = useState(toTimeString(date, 2));

  return (
    <div className="timer">
      <div className="subhead">Time-Tools</div>

      <div className="timerdesc">
        <p className="largetext">
          This section contains some time related tools. See the short
          description of the individual elements for more details. The tools are
          kept simple and will open on a neutral page without any header. At the
          moment, the tools are branded (you'll see when you open it, what the
          brand is). I'm working on a dynamic branding option but it might come
          later. <br />
          The intention is to use the timer i. e. for meetings like a daily or
          for some other timeboxing usage, the others maybe for a kiosk for a
          launch countdown or time of day display and such. At least, that was
          my use case when I developed it. Have fun!
          <br />
          <br />
          For those items with controls, set the controls and use the symbol to
          start the tool (opening a new tab)
        </p>
      </div>

      {/* Short time timer */}
      <div className="symbol">
        <a href={buildTimerURL(calcDuration(timerMin, timerSec))} target="_blank" rel="noreferrer">
          <IoIcons.IoIosTimer />{" "}
        </a>
      </div>
      <div className="descr">
        Short-time timer (counting down minutes and seconds)
      </div>
      <div className="controls">
        <form method="post" id="timerForm">
          <div className="inputHeader">Minutes & Seconds</div>
          <input
            name="minutes"
            onChange={(e) => setTimerMin(e.target.value)}
            type="number"
            className="form-control"
            defaultValue={timerMin}
            min="0"
            max="120"
          />

          <input
            type="number"
            name="seconds"
            onChange={(e) => setTimerSec(e.target.value)}
            className="form-control"
            defaultValue={timerSec}
            min="0"
            max="59"
          />
        </form>
      </div>

      {/* Digital clock */}
      <div className="symbol">
        <a href="https://www.steuernagel.net/time/index.html" target="_blank" rel="noreferrer">
          <IoIcons.IoIosClock />
        </a>
      </div>
      <div className="descr">A simple digital clock with date</div>
      <div className="controls"></div>

      {/* Date and calenderweek */}
      <div className="symbol">
        <a href="https://www.steuernagel.net/date/index.html" target="_blank" rel="noreferrer">
          <IoIcons.IoIosCalendar />
        </a>
      </div>
      <div className="descr">Displaying the current date and calendar week</div>
      <div className="controls"> </div>

      {/* Countdown timer */}
      <div className="symbol">
        <a href={buildCountdownURL(toDateFormat(countdownDate, countdownTime))} target="_blank" rel="noreferrer">
          <IoIcons.IoIosCalculator />
        </a>
      </div>
      <div className="descr">
        countdown till a specific date and time (i. e. launch-timer)
      </div>
      <div className="controls">
        <form action="" method="post"  id="countdownForm">
          <div className="inputHeader">Date & Time</div>
          <input
            className="date"
            type="date"
            id="date"
            defaultValue={countdownDate}
            onChange={(e) => setCountdownDate(e.target.value)}
          />
          <input
            className="time"
            type="time"
            id="time"
            defaultValue={countdownTime}
            onChange={(e) => setCountdownTime(e.target.value)}
          />
        </form>
      </div>
      <div className="footer"></div>
      {/* yet empty */}
    </div>
  );
}

export default Timer;
