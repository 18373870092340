export const AcChargers = [
  {
    id: 0,
    type: "AC station (22kW)",
    power: "22",
  },
  {
    id: 1,
    type: "3-phase wallbox (11kW)",
    power: "11",
  },
  {
    id: 2,
    type: "1-phase Wallbox (7kW)",
    power: "7",
  },
  {
    id: 3,
    type: "1-phase wallbox (3.6kW)",
    power: "3.6",
  },
  {
    id: 4,
    type: "1-phase standard socket (2.3kW)",
    power: "2.3",
  },
];
