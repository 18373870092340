import React from "react";

function Home() {
  return (
    <div className="template">
      <div className="header">Template Page</div>
      <div className="sub1">
        {/* This part goes on the left in desktop and in second row (after header)
        in mobile view.  */}
        SUB1 (left/2nd line): Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua.
      </div>
      <div className="sub2">
        {/* This part goes on the right in desktop and in third row in mobile view. */}
        SUB 2 (right/3rd line): At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
        gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
        ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua.
      </div>
      <div className="main">
        {/* This part goes in the middle in desktop and in fourth row in mobile
        view. */}
         MAIN (middle/4th line): Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
        amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
        nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
        sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
        rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
        ipsum dolor sit amet.
      </div>
      <div className="footer">
        {/* This part goes on the bottom in desktop and mobile view. */}
        FOOTER (bottom/5th line): At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
        gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
      </div>
    </div>
  );
}

export default Home;
