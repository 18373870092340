import React from "react";
import symbol from "../images/law.png";

function Imprint() {
  return (
    <div className="imprint">
      <div className="subhead">Impressum</div>
      <div className="imp-infos">
        <p class="sub2head">Angaben gemäß § 5 TMG</p>
        <table>
          <tr>
            <td class="col1">
              <span>Verantwortlich:</span>
            </td>
            <td class="col2">Dipl. Ing. (FH) Kai Steuernagel</td>  {/* className not defined in CSS file */}
          </tr>
          <tr>
            <td></td>
            <td>Richard-Wimmer-Str. 15a</td>
          </tr>
          <tr>
            <td></td>
            <td>63150 Heusenstamm</td>
          </tr>
          <tr>
            <td></td>
            <td>(Germany)</td>
          </tr>
          <tr>
            <td>
              <span>Kontakt:</span>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <span>e-mail:</span>
            </td>
            <td>
              k[at]steuernagel[dot]net
              <br />
              <span class="note">(Spam-safe notation)</span>
            </td>
          </tr>
        </table>
        <div className="imp-icon">
          <img className="largeparagraph" src={symbol} alt="parapgraph" />
        </div>
      </div>
      <div className="imp-legal">
        <div className="sub2head">Rechtliche Hinweise</div>
        <p>
          <span class="sub3head">Haftungsbeschränkung für eigene Inhalte</span>
          <span class="text">
            Alle Inhalte unseres Internetauftritts wurden mit Sorgfalt und nach
            bestem Gewissen erstellt. Eine Gewähr für die Aktualität,
            Vollständigkeit und Richtigkeit sämtlicher Seiten kann jedoch
            übernommen werden. Gemäß § 7 Abs. 1 TMG sind wir als Dienstanbieter
            für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
            verantwortlich, nach den §§ 8 bis 10 TMG jedoch nicht verpflichtet,
            die übermittelten oder gespeicherten fremden Informationen zu
            überwachen. Eine umgehende Entfernung dieser Inhalte erfolgt ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung und wir
            haften nicht vor dem Zeitpunkt der Kenntniserlangung.
          </span>
          <span class="sub3head"> Haftungsbeschränkung für externe Links</span>
          <span class="text">
            Unsere Webseite enthält sog. „externe Links“ (Verknüpfungen zu
            Webseiten Dritter), auf deren Inhalt wir keinen Einfluss haben und
            für den wir aus diesem Grund keine Gewähr übernehmen. Für die
            Inhalte und Richtigkeit der Informationen ist der jeweilige
            Informationsanbieter der verlinkten Webseite verantwortlich. Als die
            Verlinkung vorgenommen wurde, waren für uns keine Rechtsverstöße
            erkennbar. Sollte uns eine Rechtsverletzung bekannt werden, wird der
            jeweilige Link umgehend von uns entfernt.
          </span>
          <span class="sub3head">Urheberrecht</span>
          <span class="text">
            Die auf dieser Webseite veröffentlichten Inhalte und Werke
            unterliegen dem deutschen Urheberrecht. Jede Art der
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechts bedarf der
            vorherigen schriftlichen Zustimmung des jeweiligen Urhebers bzw.
            Autors.
          </span>
          <span class="sub3head">Datenschutz</span>
          <span class="text">
            Durch den Besuch unseres Internetauftritts können Informationen über
            den Zugriff (Datum, Uhrzeit, aufgerufene Seite) auf dem Server
            gespeichert werden. Dies stellt keine Auswertung personenbezogener
            Daten (z.B. Name, Anschrift oder E-Mail Adresse) dar. Sofern
            personenbezogene Daten erhoben werden, erfolgt dies – sofern möglich
            – nur mit dem vorherigen Einverständnis des Nutzers der Webseite.
            Eine Weiterleitung der Daten an Dritte findet ohne ausdrückliche
            Zustimmung des Nutzers nicht statt. Wir weisen ausdrücklich darauf
            hin, dass die Übertragung von Daten im Internet (z.B. per E-Mail)
            Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten
            vor dem Zugriff Dritter kann nicht gewährleistet werden. Wir können
            keine Haftung für die durch solche Sicherheitslücken entstehenden
            Schäden übernehmen. Der Verwendung veröffentlichter Kontaktdaten
            durch Dritte zum Zwecke von Werbung wird ausdrücklich widersprochen.
            Wir behalten uns rechtliche Schritte für den Fall der unverlangten
            Zusendung von Werbeinformationen, z.B. durch Spam-Mails, vor.
          </span>
        </p>
        <br />
        <div className="sub2head">Disclaimer</div>
        <p>
          <span class="sub3head">
            Limitation of liability for internal content
          </span>
          <span class="text">
            The content of our website has been compiled with meticulous care
            and to the best of our knowledge. However, we cannot assume any
            liability for the up-to-dateness, completeness or accuracy of any of
            the pages. Pursuant to section 7, para. 1 of the TMG
            (Telemediengesetz – Tele Media Act by German law), we as service
            providers are liable for our own content on these pages in
            accordance with general laws. However, pursuant to sections 8 to 10
            of the TMG, we as service providers are not under obligation to
            monitor external information provided or stored on our website. Once
            we have become aware of a specific infringement of the law, we will
            immediately remove the content in question. Any liability concerning
            this matter can only be assumed from the point in time at which the
            infringement becomes known to us.
          </span>
          <span class="sub3head">
            Limitation of liability for external links
          </span>
          <span class="text">
            Our website contains links to the websites of third parties
            („external links“). As the content of these websites is not under
            our control, we cannot assume any liability for such external
            content. In all cases, the provider of information of the linked
            websites is liable for the content and accuracy of the information
            provided. At the point in time when the links were placed, no
            infringements of the law were recognisable to us. As soon as an
            infringement of the law becomes known to us, we will immediately
            remove the link in question.
          </span>
          <span class="sub3head">Copyright</span>
          <span class="text">
            The content and works published on this website are governed by the
            copyright laws of Germany. Any duplication, processing, distribution
            or any form of utilisation beyond the scope of copyright law shall
            require the prior written consent of the author or authors in
            question.
          </span>
          <span class="sub3head">Data protection</span>
          <span class="text">
            A visit to our website can result in the storage on our server of
            information about the access (date, time, page accessed). This does
            not represent any analysis of personal data (e.g., name, address or
            e-mail address). If personal data are collected, this only occurs –
            to the extent possible – with the prior consent of the user of the
            website. Any forwarding of the data to third parties without the
            express consent of the user shall not take place. We would like to
            expressly point out that the transmission of data via the Internet
            (e.g., by e-mail) can offer security vulnerabilities. It is
            therefore impossible to safeguard the data completely against access
            by third parties. We cannot assume any liability for damages arising
            as a result of such security vulnerabilities. The use by third
            parties of all published contact details for the purpose of
            advertising is expressly excluded. We reserve the right to take
            legal steps in the case of the unsolicited sending of advertising
            information; e.g., by means of spam mail.
          </span>
          <span class="reference">
            <span>
              Source: {" "}
              <a class="link"
                href="http://www.mustervorlage.net/disclaimer-muster"
                target="_blank"
                rel="noreferrer"
              >
                Disclaimer on Mustervorlage.net
              </a>
            </span>
          </span>
        </p>
      </div>
    </div>
  );
}

export default Imprint;
