import React from 'react'
import * as AiIcons from "react-icons/ai"
import * as IoIcons from "react-icons/io"

export const SidebarData = [
    {
        title: "Home",
        path: "/",
        icons: <AiIcons.AiFillHome />,
        cName: "nav-text",
    },
    {
        title: "EV Comparisson",
        path: "/evcompare",
        icons: <IoIcons.IoMdCar />,
        cName: "nav-text",
    },
    {
        title: "EV Charging Calculator",
        path: "/evtools",
        icons: <IoIcons.IoMdFlash />,
        cName: "nav-text",
    },
    {
        title: "Timer",
        path: "/timer",
        icons: <IoIcons.IoMdClock />,
        cName: "nav-text",
    },
  /*   {
        title: "About",
        path: "/about",
        icons: <IoIcons.IoMdPerson />,
        cName: "nav-text",
    }, */
    {   
        title: "Tilgungsplan",
        path: "/tilgung",
        icons: <IoIcons.IoIosCalculator />,
        cName: "nav-text",
    },
    {
        title: "Imprint",
        path: "/imprint",
        icons: <IoIcons.IoMdBook />,
        cName: "nav-text",
    },
    /* {
        title: "Template",
        path: "/template",
        icons: <IoIcons.IoIosApps />,
        cName: "nav-text",
    }, */
  
]