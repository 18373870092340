export const EVehicles = [
  {
    brand: "Audi",
    model: "Q4 etron",
    variant: "40",
    modelYear: "2022",
    batterySize: "82",
    ACCharge: "11",
    DCCharge: [160, 175, 175, 135, 120, 100, 85, 70, 75, 45, 30, 30],
    ACLoss: "200",
    DCmax: "143",
    WLTPreach: "520",
    realReach: "335",
    length: "4588",
    width: "1865",
    power: "150",
    trunkVol: "520",
    frunkVol: "0",
    turnCircle: "10.2",
    maxLoad: "2640",
    trailerMax: "1000",
  },
  {
    brand: "Audi",
    model: "Q8 etron",
    variant: "50",
    modelYear: "2023",
    batterySize: "89",
    ACCharge: "11",
    DCCharge: [125, 140, 145, 146, 147, 152, 155, 145, 90, 50, 30, 30],
    ACLoss: "200",
    DCmax: "155",
    WLTPreach: "491",
    realReach: "295",
    length: "4915",
    width: "1937",
    power: "250",
    trunkVol: "569",
    frunkVol: "62",
    turnCircle: "",
    maxLoad: "3170",
    trailerMax: "1800",
  },
  {
    brand: "BMW",
    model: "iX3",
    variant: "",
    modelYear: "2021",
    batterySize: "74",
    ACCharge: "11",
    DCCharge: [140, 155, 145, 119, 112, 100, 75, 70, 52, 45, 25, 25],
    ACLoss: "200",
    DCmax: "155",
    WLTPreach: "461",
    realReach: "295",
    length: "4734",
    width: "1891",
    power: "210",
    trunkVol: "510",
    frunkVol: "0",
    turnCircle: "12.1",
    maxLoad: "2725",
    trailerMax: "750",
  },
  {
    brand: "BMW",
    model: "i4",
    variant: "eDrive 40",
    modelYear: "2022",
    batterySize: "81",
    ACCharge: "11",
    DCCharge: [190, 205, 200, 175, 145, 105, 85, 73, 57, 47, 35, 35],
    ACLoss: "200",
    DCmax: "207",
    WLTPreach: "589",
    realReach: "400",
    length: "4783",
    width: "1852",
    power: "250",
    trunkVol: "470",
    frunkVol: "0",
    turnCircle: "12.5",
    maxLoad: "2605",
    trailerMax: "1600",
  },
  {
    brand: "Citroen",
    model: "e-C4",
    variant: "136",
    modelYear: "2021",
    batterySize: "46",
    ACCharge: "11",
    DCCharge: [90, 100, 92, 92, 80, 80, 67, 57, 35, 30, 25, 25],
    DCmax: "100",
    ACLoss: "200",
    WLTPreach: "357",
    realReach: "240",
    length: "4360",
    width: "1800",
    power: "100",
    trunkVol: "380",
    frunkVol: "0",
    turnCircle: "10.9",
    maxLoad: "2020",
    trailerMax: "0",
  },
  {
    brand: "Citroen",
    model: "e-C4",
    variant: "156",
    modelYear: "2023",
    batterySize: "51",
    ACCharge: "11",
    DCCharge: [90, 100, 92, 92, 80, 80, 67, 57, 35, 30, 25, 25],
    DCmax: "100",
    ACLoss: "200",
    WLTPreach: "420",
    realReach: "270",
    length: "4360",
    width: "1800",
    power: "115",
    trunkVol: "380",
    frunkVol: "0",
    turnCircle: "10.9",
    maxLoad: "2020",
    trailerMax: "0",
  },
  {
    brand: "Citroen",
    model: "e-C4 X",
    variant: "136",
    modelYear: "2023",
    batterySize: "46",
    ACCharge: "11",
    DCCharge: [90, 100, 92, 92, 80, 80, 67, 57, 35, 30, 25, 25],
    DCmax: "100",
    ACLoss: "200",
    WLTPreach: "362",
    realReach: "230",
    length: "4600",
    width: "1800",
    power: "100",
    trunkVol: "510",
    frunkVol: "0",
    turnCircle: "10.9",
    maxLoad: "2040",
    trailerMax: "0",
  },
  {
    brand: "Citroen",
    model: "e-C4 X",
    variant: "156",
    modelYear: "2023",
    batterySize: "51",
    ACCharge: "11",
    DCCharge: [90, 100, 92, 92, 80, 80, 67, 57, 35, 30, 25, 25],
    DCmax: "100",
    ACLoss: "200",
    WLTPreach: "422",
    realReach: "270",
    length: "4600",
    width: "1800",
    power: "115",
    trunkVol: "510",
    frunkVol: "0",
    turnCircle: "10.9",
    maxLoad: "2040",
    trailerMax: "0",
  },
  {
    brand: "Citroen",
    model: "e-C3",
    variant: "YOU",
    modelYear: "2024",
    batterySize: "44",
    ACCharge: "7,4",
    DCCharge: [90, 100, 92, 92, 80, 80, 67, 57, 35, 30, 25, 25],
    DCmax: "100",
    ACLoss: "200",
    WLTPreach: "300",
    realReach: "220",
    length: "4015",
    width: "1755",
    power: "83",
    trunkVol: "310",
    frunkVol: "0",
    turnCircle: "10,7",
    maxLoad: "1910",
    trailerMax: "550",
  },
  {
    brand: "Dacia",
    model: "Spring Electric",
    variant: "45 Essential",
    modelYear: "2022",
    batterySize: "25",
    ACCharge: "6,6",
    DCCharge: [30, 34, 34, 34, 34, 34, 34, 34, 34, 30, 25, 25],
    DCmax: "34",
    ACLoss: "200",
    WLTPreach: "230",
    realReach: "140",
    length: "3734",
    width: "1622",
    power: "33",
    trunkVol: "300",
    frunkVol: "0",
    turnCircle: "9,5",
    maxLoad: "1300",
    trailerMax: "0",
  },
  {
    brand: "Dacia",
    model: "Spring Electric",
    variant: "65 Extreme",
    modelYear: "2023",
    batterySize: "25",
    ACCharge: "6,6",
    DCCharge: [30, 34, 34, 34, 34, 34, 34, 34, 34, 30, 25, 25],
    DCmax: "34",
    ACLoss: "200",
    WLTPreach: "220",
    realReach: "130",
    length: "3734",
    width: "1622",
    power: "48",
    trunkVol: "300",
    frunkVol: "0",
    turnCircle: "9,5",
    maxLoad: "1300",
    trailerMax: "0",
  },
  {
    brand: "Fiat",
    model: "500-e",
    variant: "42kWh",
    modelYear: "2020",
    batterySize: "37",
    ACCharge: "11",
    DCCharge: [70, 73, 85, 81, 67, 65, 53, 53, 43, 13, 13, 13], 
    DCmax: "85",
    ACLoss: "200",
    WLTPreach: "331",
    realReach: "190",
    length: "3631",
    width: "1683",
    power: "87",
    trunkVol: "185",
    frunkVol: "0",
    turnCircle: "9,7",
    maxLoad: "1590",
    trailerMax: "0",
  },
  {
    brand: "Fiat",
    model: "500-e",
    variant: "24kWh",
    modelYear: "2020",
    batterySize: "21",
    ACCharge: "11",
    ACCharge: "22",
    DCCharge: [40, 42, 50, 49, 48, 46, 40, 35, 27, 25, 25, 25],
    DCmax: "50",
    ACLoss: "200",
    WLTPreach: "190",
    realReach: "110",
    length: "3631",
    width: "1683",
    power: "70",
    trunkVol: "185",
    frunkVol: "0",
    turnCircle: "9,7",
    maxLoad: "1560",
    trailerMax: "0",
  },
  {
    brand: "Honda",
    model: "e",
    variant: "",
    modelYear: "2020",
    batterySize: "29",
    ACCharge: "6,6",
    ACCharge: "22",
    DCCharge: [45, 46, 37, 35, 38, 30, 31, 20, 20, 21, 11, 11],
    DCmax: "46",
    ACLoss: "200",
    WLTPreach: "222",
    realReach: "140",
    length: "3894",
    width: "1752",
    power: "100",
    trunkVol: "171",
    frunkVol: "0",
    turnCircle: "9,2",
    maxLoad: "1855",
    trailerMax: "0",
  },
  {
    brand: "Hyundai",
    model: "Kona",
    variant: "65 kWh",
    modelYear: "2024",
    batterySize: "65",
    ACCharge: "11",
    DCCharge: [62, 71, 74, 77, 70, 71, 59, 57, 26, 22, 8, 8],
    ACLoss: "200",
    DCmax: "100",
    WLTPreach: "484",
    realReach: "310",
    length: "4355",
    width: "1825",
    power: "160",
    trunkVol: "466",
    frunkVol: "0",
    turnCircle: "10.6",
    maxLoad: "2220",
    trailerMax: "750",
  },
  {
    brand: "Hyundai",
    model: "Kona",
    variant: "64 kWh",
    modelYear: "2022",
    batterySize: "64",
    ACCharge: "11",
    DCCharge: [62, 71, 74, 77, 70, 71, 59, 57, 26, 22, 8, 8],
    ACLoss: "200",
    DCmax: "77",
    WLTPreach: "484",
    realReach: "310",
    length: "4205",
    width: "1800",
    power: "150",
    trunkVol: "322",
    frunkVol: "0",
    turnCircle: "10.6",
    maxLoad: "2170",
    trailerMax: "750",
  },
  {
    brand: "Hyundai",
    model: "Kona",
    variant: "39 kWh",
    modelYear: "2022",
    batterySize: "39",
    ACCharge: "7.2",
    DCCharge: [35, 39, 43, 44, 44, 44, 37, 25, 15, 13, 8, 8],
    ACLoss: "200",
    DCmax: "44",
    WLTPreach: "305",
    realReach: "200",
    length: "4205",
    width: "1800",
    power: "100",
    trunkVol: "332",
    frunkVol: "0",
    turnCircle: "10.6",
    maxLoad: "2020",
    trailerMax: "0",
  },
  {
    brand: "Nissan",
    model: "Leaf",
    variant: "40 kWh",
    modelYear: "2022",
    batterySize: "39",
    ACCharge: "3.6",
    DCCharge: [41, 42, 43, 44, 45, 45, 46, 34, 27, 21, 15, 15],
    ACLoss: "200",
    DCmax: "46",
    WLTPreach: "350",
    realReach: "200",
    length: "4490",
    width: "1788",
    power: "178",
    trunkVol: "435",
    frunkVol: "0",
    turnCircle: "10.6",
    maxLoad: "1995",
    trailerMax: "0",
  },
  {
    brand: "Opel",
    model: "Corsa-e",
    variant: "",
    modelYear: "2021",
    batterySize: "46",
    ACCharge: "11",
    DCCharge: [90, 100, 92, 92, 80, 80, 67, 57, 35, 30, 25, 25],
    ACLoss: "200",
    DCmax: "101",
    WLTPreach: "362",
    realReach: "235",
    length: "4060",
    width: "1765",
    power: "100",
    trunkVol: "309",
    frunkVol: "0",
    turnCircle: "10.4",
    maxLoad: "1920",
    trailerMax: "0",
  },
  {
    brand: "Opel",
    model: "Corsa",
    variant: "electric",
    modelYear: "2023",
    batterySize: "50",
    ACCharge: "7,4",
    DCCharge: [90, 100, 92, 92, 80, 80, 67, 57, 35, 30, 25, 25],
    DCmax: "100",
    ACLoss: "295",
    WLTPreach: "354",
    realReach: "230",
    length: "4060",
    width: "1765",
    power: "100",
    trunkVol: "267",
    frunkVol: "0",
    turnCircle: "10,7",
    maxLoad: "1961",
    trailerMax: "0",
  },
  {
    brand: "Opel",
    model: "Corsa",
    variant: "electric GS long range",
    modelYear: "2023",
    batterySize: "51",
    ACCharge: "7,4",
    DCCharge: [90, 100, 92, 92, 80, 80, 67, 57, 35, 30, 25, 25],
    DCmax: "100",
    ACLoss: "315",
    WLTPreach: "406",
    realReach: "260",
    length: "4060",
    width: "1765",
    power: "115",
    trunkVol: "267",
    frunkVol: "0",
    turnCircle: "10,7",
    maxLoad: "1920",
    trailerMax: "0",
  },
  {
    brand: "Peugeot",
    model: "e208",
    variant: "Active/Allure/ GT-50kW",
    modelYear: "2021",
    batterySize: "46",
    ACCharge: "11",
    DCCharge: [90, 100, 92, 92, 80, 80, 67, 57, 35, 30, 25, 25],
    ACLoss: "200",
    DCmax: "101",
    WLTPreach: "362",
    realReach: "235",
    length: "4055",
    width: "1765",
    power: "100",
    trunkVol: "265",
    frunkVol: "0",
    turnCircle: "10.4",
    maxLoad: "1910",
    trailerMax: "0",
  },
  {
    brand: "Peugeot",
    model: "e208",
    variant: "Active/Allure/ GT-50kW",
    modelYear: "2024",
    batterySize: "46",
    ACCharge: "11",
    DCCharge: [90, 100, 92, 92, 80, 80, 67, 57, 35, 30, 25, 25],
    ACLoss: "200",
    DCmax: "101",
    WLTPreach: "362",
    realReach: "235",
    length: "4055",
    width: "1765",
    power: "100",
    trunkVol: "265",
    frunkVol: "0",
    turnCircle: "10.4",
    maxLoad: "1910",
    trailerMax: "0",
  },
  {
    brand: "Peugeot",
    model: "e2008",
    variant: "Active/Allure/ GT-50kW",
    modelYear: "2021",
    batterySize: "46",
    ACCharge: "11",
    DCCharge: [90, 100, 92, 92, 80, 80, 67, 57, 35, 30, 25, 25],
    ACLoss: "200",
    DCmax: "101",
    WLTPreach: "342",
    realReach: "220",
    length: "4300",
    width: "1770",
    power: "100",
    trunkVol: "434",
    frunkVol: "0",
    turnCircle: "10.4",
    maxLoad: "2030",
    trailerMax: "0",
  },
  {
    brand: "Peugeot",
    model: "e2008",
    variant: "Active/Allure/ GT-50kW",
    modelYear: "2024",
    batterySize: "46",
    ACCharge: "7.4",
    DCCharge: [90, 100, 92, 92, 80, 80, 67, 57, 35, 30, 25, 25],
    ACLoss: "200",
    DCmax: "101",
    WLTPreach: "340",
    realReach: "220",
    length: "4304",
    width: "1775",
    power: "100",
    trunkVol: "434",
    frunkVol: "0",
    turnCircle: "10.4",
    maxLoad: "2030",
    trailerMax: "0",
  },
  {
    brand: "Peugeot",
    model: "e2008",
    variant: "GT 54kW",
    modelYear: "2024",
    batterySize: "50",
    ACCharge: "7.4",
    DCCharge: [90, 100, 92, 92, 80, 80, 67, 57, 35, 30, 25, 25],
    ACLoss: "200",
    DCmax: "101",
    WLTPreach: "406",
    realReach: "260",
    length: "4304",
    width: "1775",
    power: "115",
    trunkVol: "434",
    frunkVol: "0",
    turnCircle: "10.4",
    maxLoad: "2030",
    trailerMax: "0",
  },
  {
    brand: "Polestar",
    model: "2",
    variant: "long range",
    modelYear: "2023",
    batterySize: "78",
    ACCharge: "11",
    DCCharge: [140, 148, 149, 137, 125, 106, 87, 65, 37, 12, 10, 10],
    ACLoss: "200",
    DCmax: "149",
    WLTPreach: "551",
    realReach: "355",
    length: "4606",
    width: "1859",
    power: "170",
    trunkVol: "405",
    frunkVol: "41",
    turnCircle: "11.5",
    maxLoad: "2600",
    trailerMax: "1500",
  },
  {
    brand: "Polestar",
    model: "2",
    variant: "standard range",
    modelYear: "2023",
    batterySize: "69",
    ACCharge: "11",
    DCCharge: [130, 132, 132, 133, 126, 120, 106, 89, 70, 50, 10, 10],
    ACLoss: "200",
    DCmax: "135",
    WLTPreach: "478",
    realReach: "310",
    length: "4606",
    width: "1859",
    power: "170",
    trunkVol: "405",
    frunkVol: "41",
    turnCircle: "11.5",
    maxLoad: "2600",
    trailerMax: "1500",
  },
    {
      brand: "Polestar",
      model: "2",
      variant: "dual motor",
      modelYear: "2024",
      batterySize: "79",
      ACCharge: "11",
      DCCharge: [195, 203, 205, 195, 157, 133, 110, 90, 55, 22, 22, 10],
      DCmax: "205",
      ACLoss: "200",
      WLTPreach: "591",
      realReach: "380",
      length: "4606",
      width: "1859",
      power: "310",
      trunkVol: "405",
      frunkVol: "41",
      turnCircle: "2600",
      maxLoad: "11,5",
      trailerMax: "1500"
    },
    {
      brand: "Polestar",
      model: "2",
      variant: "dual motor",
      modelYear: "2023",
      batterySize: "75",
      ACCharge: "11",
      DCCharge: [140, 148, 149, 137, 125, 106, 87, 65, 37, 12, 10, 10],
      DCmax: "149",
      ACLoss: "200",
      WLTPreach: "487",
      realReach: "320",
      length: "4606",
      width: "1859",
      power: "300",
      trunkVol: "405",
      frunkVol: "41",
      turnCircle: "2600",
      maxLoad: "11,5",
      trailerMax: "1500"
    },
    {
      brand: "Polestar",
      model: "3",
      variant: "dual motor",
      modelYear: "2024",
      batterySize: "107",
      ACCharge: "11",
      DCCharge: [220, 230, 250, 220, 187, 155, 130, 110, 75, 55, 35, 10],
      DCmax: "250",
      ACLoss: "200",
      WLTPreach: "610",
      realReach: "400",
      length: "4900",
      width: "1968",
      power: "360",
      trunkVol: "484",
      frunkVol: "32",
      turnCircle: "3080",
      maxLoad: "11,8",
      trailerMax: "2200"
    },
    {
      brand: "Polestar",
      model: "3",
      variant: "dual motor performance",
      modelYear: "2024",
      batterySize: "107",
      ACCharge: "11",
      DCCharge: [220, 230, 250, 220, 187, 155, 130, 110, 75, 55, 35, 10],
      DCmax: "250",
      ACLoss: "200",
      WLTPreach: "560",
      realReach: "360",
      length: "4900",
      width: "1968",
      power: "380",
      trunkVol: "484",
      frunkVol: "32",
      turnCircle: "3080",
      maxLoad: "11,8",
      trailerMax: "2200"
    },
    {
      brand: "Polestar",
      model: "4",
      variant: "Long Range Dual Motor",
      modelYear: "2024",
      batterySize: "94",
      ACCharge: "11",
      DCCharge: [195, 198, 200, 195, 157, 133, 110, 90, 55, 22, 22, 10],
      DCmax: "200",
      ACLoss: "200",
      WLTPreach: "580",
      realReach: "420",
      length: "4840",
      width: "2008",
      power: "400",
      trunkVol: "526",
      frunkVol: "",
      turnCircle: "",
      maxLoad: "11,6",
      trailerMax: "2000"
    },
    {
      brand: "Polestar",
      model: "4",
      variant: "Long Range Single Motor",
      modelYear: "2024",
      batterySize: "94",
      ACCharge: "11",
      DCCharge: [195, 198, 200, 195, 157, 133, 110, 90, 55, 22, 22, 10],
      DCmax: "200",
      ACLoss: "200",
      WLTPreach: "610",
      realReach: "400",
      length: "4840",
      width: "2008",
      power: "200",
      trunkVol: "526",
      frunkVol: "",
      turnCircle: "",
      maxLoad: "11,6",
      trailerMax: "1500"
    },
  {
    brand: "Renault",
    model: "Zoe",
    variant: "135 with CCS",
    modelYear: "2022",
    batterySize: "55",
    ACCharge: "22",
    DCCharge: [40, 42, 44, 45, 45, 46, 40, 35, 27, 25, 25, 25],
    ACLoss: "400",
    DCmax: "46",
    WLTPreach: "385",
    realReach: "250",
    length: "4087",
    width: "1787",
    power: "100",
    trunkVol: "338",
    frunkVol: "0",
    turnCircle: "10.6",
    maxLoad: "1988",
    trailerMax: "0",
  },
  {
    brand: "Skoda",
    model: "Enyaq",
    variant: "iV 80",
    modelYear: "2021",
    batterySize: "77",
    ACCharge: "11",
    DCCharge: [130, 140, 135, 135, 135, 130, 125, 110, 80, 35, 25, 25],
    DCmax: "143",
    ACLoss: "200",
    WLTPreach: "548",
    realReach: "350",
    length: "4649",
    width: "1879",
    power: "210",
    trunkVol: "585",
    frunkVol: "0",
    turnCircle: "9.3",
    maxLoad: "2540",
    trailerMax: "1000",
  },
  {
    brand: "Skoda",
    model: "Enyaq",
    variant: "iV 60",
    modelYear: "2021",
    batterySize: "58",
    ACCharge: "11",
    DCCharge: [100, 120, 123, 100, 85, 75, 60, 50, 50, 30, 25, 25],
    DCmax: "124",
    ACLoss: "200",
    WLTPreach: "413",
    realReach: "270",
    length: "4649",
    width: "1879",
    power: "132",
    trunkVol: "585",
    frunkVol: "0",
    turnCircle: "9.3",
    maxLoad: "2500",
    trailerMax: "1000",
  },
  {
    brand: "Tesla",
    model: "3",
    variant: "standard range",
    modelYear: "2019",
    batterySize: "51",
    ACCharge: "11",
    DCCharge: [160, 170, 155, 137, 115, 90, 70, 57, 37, 22, 15, 15],
    ACLoss: "200",
    DCmax: "170",
    WLTPreach: "495",
    realReach: "320",
    length: "4694",
    width: "1849",
    power: "170",
    trunkVol: "561",
    frunkVol: "88",
    turnCircle: "11.6",
    maxLoad: "2149",
    trailerMax: "1000",
  },
  {
    brand: "Tesla",
    model: "3",
    variant: "long range dual motor",
    modelYear: "2019",
    batterySize: "74",
    ACCharge: "11",
    DCCharge: [160, 180, 188, 192, 193, 155, 110, 90, 55, 28, 20, 20],
    ACLoss: "200",
    DCmax: "250",
    WLTPreach: "678",
    realReach: "460",
    length: "4694",
    width: "1849",
    power: "366",
    trunkVol: "561",
    frunkVol: "88",
    turnCircle: "11.6",
    maxLoad: "2149",
    trailerMax: "1000",
  },
  {
    brand: "Tesla",
    model: "Y",
    variant: "standard range",
    modelYear: "2023",
    batterySize: "57",
    ACCharge: "11",
    DCCharge: [160, 180, 188, 192, 193, 155, 110, 90, 55, 28, 20, 20],
    ACLoss: "200",
    DCmax: "170",
    WLTPreach: "455",
    realReach: "275",
    length: "4751",
    width: "1921",
    power: "220",
    trunkVol: "854",
    frunkVol: "117",
    turnCircle: "12.1",
    maxLoad: "2448",
    trailerMax: "1600",
  },
  {
    brand: "Tesla",
    model: "S",
    variant: "Plaid",
    modelYear: "2022",
    batterySize: "95",
    ACCharge: "11",
    DCCharge: [100, 205, 210, 215, 190, 145, 115, 90, 65, 45, 20, 20],
    ACLoss: "200",
    DCmax: "250",
    WLTPreach: "600",
    realReach: "430",
    length: "5021",
    width: "1987",
    power: "760",
    trunkVol: "709",
    frunkVol: "89",
    turnCircle: "12.3",
    maxLoad: "2629",
    trailerMax: "1600",
  },
  {
    brand: "Volkswagen",
    model: "ID.3",
    variant: "pro",
    modelYear: "2023",
    batterySize: "58",
    ACCharge: "11",
    DCCharge: [100, 100, 120, 105, 88, 75, 67, 55, 50, 32, 25, 25],
    ACLoss: "200",
    DCmax: "124",
    WLTPreach: "427",
    realReach: "275",
    length: "4261",
    width: "1837",
    power: "150",
    trunkVol: "385",
    frunkVol: "0",
    turnCircle: "10.2",
    maxLoad: "2270",
    trailerMax: "0",
  },
  {
    brand: "Volkswagen",
    model: "ID.3",
    variant: "pro S",
    modelYear: "2023",
    batterySize: "82",
    ACCharge: "11",
    DCCharge: [150, 167, 175, 144, 122, 105, 89, 72, 62, 50, 30, 30],
    ACLoss: "200",
    DCmax: "175",
    WLTPreach: "559",
    realReach: "360",
    length: "4261",
    width: "1837",
    power: "150",
    trunkVol: "385",
    frunkVol: "0",
    turnCircle: "10.3",
    maxLoad: "2280",
    trailerMax: "0",
  },
  {
    brand: "Volkswagen",
    model: "ID.4",
    variant: "Pro",
    modelYear: "2024",
    batterySize: "77",
    ACCharge: "11",
    DCCharge: [90, 100, 92, 92, 80, 80, 67, 57, 35, 30, 25, 25],
    DCmax: "135",
    ACLoss: "200",
    WLTPreach: "550",
    realReach: "350",
    length: "4584",
    width: "1852",
    power: "210",
    trunkVol: "543",
    frunkVol: "0",
    turnCircle: "10,2",
    maxLoad: "2670",
    trailerMax: "1000",
  },
  {
    brand: "Volkswagen",
    model: "ID.4",
    variant: "Pro Performance",
    modelYear: "2021",
    batterySize: "77",
    ACCharge: "11",
    DCCharge: [120, 143, 140, 135, 120, 110, 90, 77, 55, 44, 33, 25],
    DCmax: "143",
    ACLoss: "200",
    WLTPreach: "537",
    realReach: "330",
    length: "4584",
    width: "1852",
    power: "150",
    trunkVol: "543",
    frunkVol: "0",
    turnCircle: "10,2",
    maxLoad: "2540",
    trailerMax: "1000",
  },
  {
    brand: "Volkswagen",
    model: "ID.4",
    variant: "GTX",
    modelYear: "2024",
    batterySize: "77",
    ACCharge: "11",
    DCCharge: [150, 167, 175, 144, 122, 105, 89, 72, 62, 50, 30, 30],
    DCmax: "175",
    ACLoss: "200",
    WLTPreach: "515",
    realReach: "340",
    length: "4584",
    width: "1852",
    power: "250",
    trunkVol: "543",
    frunkVol: "0",
    turnCircle: "11,6",
    maxLoad: "2770",
    trailerMax: "1200",
  },
  {
    brand: "Volkswagen",
    model: "ID.4",
    variant: "GTX",
    modelYear: "2022",
    batterySize: "77",
    ACCharge: "11",
    DCCharge: [150, 167, 175, 144, 122, 105, 89, 72, 62, 50, 30, 30],
    DCmax: "175",
    ACLoss: "200",
    WLTPreach: "488",
    realReach: "330",
    length: "4584",
    width: "1852",
    power: "220",
    trunkVol: "543",
    frunkVol: "0",
    turnCircle: "11,6",
    maxLoad: "2750",
    trailerMax: "1200",
  },
  {
    brand: "Volkswagen",
    model: "ID.5",
    variant: "Pro",
    modelYear: "2024",
    batterySize: "77",
    ACCharge: "11",
    DCCharge: [110, 135, 130, 120, 110, 90, 77, 55, 44, 33, 30, 25],
    DCmax: "135",
    ACLoss: "200",
    WLTPreach: "556",
    realReach: "370",
    length: "4599",
    width: "1852",
    power: "210",
    trunkVol: "549",
    frunkVol: "0",
    turnCircle: "10,2",
    maxLoad: "2680",
    trailerMax: "1000",
  },
  {
    brand: "Volkswagen",
    model: "ID.5",
    variant: "Pro",
    modelYear: "2021",
    batterySize: "77",
    ACCharge: "11",
    DCCharge: [120, 143, 140, 135, 120, 110, 90, 77, 55, 44, 33, 25],
    DCmax: "143",
    ACLoss: "200",
    WLTPreach: "536",
    realReach: "350",
    length: "4599",
    width: "1852",
    power: "128",
    trunkVol: "549",
    frunkVol: "0",
    turnCircle: "10,2",
    maxLoad: "2680",
    trailerMax: "1000",
  },
  {
    brand: "Volkswagen",
    model: "ID.5",
    variant: "Pro Performance",
    modelYear: "2022",
    batterySize: "77",
    ACCharge: "11",
    DCCharge: [120, 143, 140, 135, 120, 110, 90, 77, 55, 44, 33, 25],
    DCmax: "143",
    ACLoss: "200",
    WLTPreach: "544",
    realReach: "350",
    length: "4599",
    width: "1852",
    power: "150",
    trunkVol: "549",
    frunkVol: "0",
    turnCircle: "10,2",
    maxLoad: "2650",
    trailerMax: "1000",
  },
  {
    brand: "Volkswagen",
    model: "ID.5",
    variant: "GTX",
    modelYear: "2024",
    batterySize: "77",
    ACCharge: "11",
    DCCharge: [150, 167, 175, 144, 122, 105, 89, 72, 62, 50, 30, 30],
    DCmax: "175",
    ACLoss: "200",
    WLTPreach: "533",
    realReach: "350",
    length: "4599",
    width: "1852",
    power: "250",
    trunkVol: "549",
    frunkVol: "0",
    turnCircle: "11,6",
    maxLoad: "2760",
    trailerMax: "1200",
  },
  {
    brand: "Volkswagen",
    model: "ID.5",
    variant: "GTX",
    modelYear: "2022",
    batterySize: "77",
    ACCharge: "11",
    DCCharge: [150, 167, 175, 144, 122, 105, 89, 72, 62, 50, 30, 30],
    DCmax: "175",
    ACLoss: "200",
    WLTPreach: "517",
    realReach: "330",
    length: "4599",
    width: "1852",
    power: "220",
    trunkVol: "549",
    frunkVol: "0",
    turnCircle: "11,6",
    maxLoad: "2750",
    trailerMax: "1200",
  },
  {
    brand: "Volkswagen",
    model: "ID.7",
    variant: "Pro",
    modelYear: "2024",
    batterySize: "77",
    ACCharge: "11",
    DCCharge: [150, 167, 175, 144, 122, 105, 89, 72, 62, 50, 30, 30],
    DCmax: "175",
    ACLoss: "200",
    WLTPreach: "621",
    realReach: "380",
    length: "4961",
    width: "1862",
    power: "210",
    trunkVol: "532",
    frunkVol: "0",
    turnCircle: "10,9",
    maxLoad: "2630",
    trailerMax: "1200",
  },
  {
    brand: "Volkswagen",
    model: "ID.7",
    variant: "Pro S",
    modelYear: "2024",
    batterySize: "86",
    ACCharge: "11",
    DCCharge: [170, 185, 200, 195, 155, 135, 110, 92, 82, 60, 40, 30],
    DCmax: "200",
    ACLoss: "200",
    WLTPreach: "700",
    realReach: "420",
    length: "4961",
    width: "1862",
    power: "210",
    trunkVol: "532",
    frunkVol: "0",
    turnCircle: "10,9",
    maxLoad: "2630",
    trailerMax: "1200",
  },
];
