// EVTableColumns.js

import { ColumnFilter } from "./ColumnFilter";

export const COLUMNS = [
  {
    header: "Car make and model",
    columns: [
      {
        header: "Brand",
        accessorKey: "brand",
        footer: props => props.column.id,
        cell: info => info.getValue(),
        size: 100,
        maxSize: 100,
        minSize: 100,
      },
      {
        header: "Model",
        accessorKey: "model",
        footer: props => props.column.id,
        cell: info => info.getValue(),
        size: 300,
      },
      {
        header: "Variant",
        accessorKey: "variant",
        footer: props => props.column.id,
        cell: info => info.getValue(),
      },
      {
        header: "Year",
        accessorKey: "modelYear",
        footer: props => props.column.id,
        cell: info => info.getValue(),
      },
    ],
  },
  {
    header: "Electrical Characteristics",
    columns: [
      {
        header: "Power (kW)",
        accessorKey: "power",
        footer: props => props.column.id,
        cell: info => info.getValue(),
      },
      {
        header: "Battery Size (kWh)",
        accessorKey: "batterySize",
        footer: props => props.column.id,
        cell: info => info.getValue(),
      },
      {
        header: "max AC-Charge (kW)",
        accessorKey: "ACCharge",
        footer: props => props.column.id,
        cell: info => info.getValue(),
      },
      {
        header: "max DC-Charge (kW)",
        accessorKey: "DCmax",
        footer: props => props.column.id,
        cell: info => info.getValue(),
      },
      {
        header: "WLTP Reach (km)",
        accessorKey: "WLTPreach",
        footer: props => props.column.id,
        cell: info => info.getValue(),
      },
      {
        header: "real. 90-10% Reach (km)",
        accessorKey: "realReach",
        footer: props => props.column.id,
        cell: info => info.getValue(),
      },
    ],
  },
  {
    header: "Physical Characteristics",
    columns: [
      {
        header: "Length (mm)",
        accessorKey: "length",
        footer: props => props.column.id,
        cell: info => info.getValue(),
      },
      {
        header: "Width (mm)",
        accessorKey: "width",
        footer: props => props.column.id,
        cell: info => info.getValue(),
      },
      {
        header: "Trunk volume (l)",
        accessorKey: "trunkVol",
        footer: props => props.column.id,
        cell: info => info.getValue(),
      },
      {
        header: "Front trunk vol. (l)",
        accessorKey: "frunkVol",
        footer: props => props.column.id,
        cell: info => info.getValue(),
      },
      {
        header: "turning circle (m)",
        accessorKey: "turnCircle",
        footer: props => props.column.id,
        cell: info => info.getValue(),
      },
      {
        header: "Gross weight (kg)",
        accessorKey: "maxLoad",
        footer: props => props.column.id,
        cell: info => info.getValue(),
      },
      {
        header: "max towing weight (kg)",
        accessorKey: "trailerMax",
        footer: props => props.column.id,
        cell: info => info.getValue(),
      },
    ],
  },
];
