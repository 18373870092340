import React, { useState } from "react";
import { Chart as ChartJS } from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Line } from "react-chartjs-2";
import { EVehicles } from "../components/EVehicles";
import { DcChargers } from "../components/DcChargers";
import { AcChargers } from "../components/AcChargers";

// *** Function for changing between absolut and relative soc display *** TODO: to be used properly with a selection input (radio)

function selectDataType(type) {
  if (type === "abs") return "Charge absolut";
  else return "Charge %";
}

// *** Function to create a list for the dropdown field ***

function carList() {
  let list = [];
  let indiv = { name: null, id: null };
  for (let id = 0; id < EVehicles.length; id++) {
    let name =
      EVehicles[id].brand +
      " " +
      EVehicles[id].model +
      " " +
      EVehicles[id].variant +
      " (" +
      EVehicles[id].modelYear +
      ")";
    indiv = { name, id: id };

    list.push(indiv);
  }
  return list;
}

function getDigit(int) {
  let digit = int - Math.floor(int / 10) * 10;
  return digit;
}

function removeDigit(int) {
  return int - getDigit(int);
}

function convertMin(min) {
  let hours = 0;
  let sec = 0;
  if (min.toFixed(0) >= 60) {
    hours = Math.floor(min / 60);
  }
  sec = (Math.abs(min) - Math.floor(min)) * 60;
  min = Math.floor(min) - hours * 60;
  return { hours, min, sec };
}

// *** Function for displaying the duration of the charging process - increment by state of charge ***

function calcDuration(idx, startSoc, targetSoc, dcmax) {
  let min = 0;

  for (let i = startSoc; i <= targetSoc - 1; i++) {
    let dcPower = Math.min(
      (EVehicles[idx].DCCharge[removeDigit(i) / 10] +
        EVehicles[idx].DCCharge[removeDigit(i) / 10 + 1]) /
        2,
      DcChargers[dcmax].power
    );

    min =
      min +
      (EVehicles[idx].batterySize /
        100 / // 1% of charge for each iteration
        dcPower) * // average of charging speed for the 1% charge
        60; // times 60 minutes as charge was in kW *hours*
  }
  let time = convertMin(min);
  return (
    time.hours + "h " + time.min.toFixed(0) + "m " + time.sec.toFixed(0) + "s"
  );
}

function calcAcDuration(idx, startSoc, targetSoc, acmax) {
  let acPower = Math.min(EVehicles[idx].ACCharge, AcChargers[acmax].power);
  let acEfficiency = (acPower - EVehicles[idx].ACLoss / 1000) / acPower;
  console.log("eff:", acEfficiency);

  let min =
    ((((targetSoc - startSoc) / 100) * EVehicles[idx].batterySize) / acPower) *
    60;
  let time = convertMin(min);
  return (
    time.hours + "h " + time.min.toFixed(0) + "m " + time.sec.toFixed(0) + "s"
  );
}

// *** Function for creating the Data for the Graph - increment by minute ***

function chargingProcess(idx, startSoc, targetSoc, dcmax) {
  let min = 0;
  let chargeProcess = [];

  let soc = {
    abs: (EVehicles[idx].batterySize * startSoc) / 100,
    rel: startSoc,
    speed: EVehicles[idx].DCCharge[0],
    min: min,
  };

  chargeProcess.push(soc);

  while (soc.rel <= targetSoc) {
    let startIdx = removeDigit(soc.rel) / 10; // the upper (lower soc) index number for the DCCharge array
    let interSoc = soc.rel - startIdx * 10; // the intermediate step between the upper and lower index
    let upperCharge = EVehicles[idx].DCCharge[startIdx.toFixed(0)]; // the loading speed in the beginning of the interval
    let lowerCharge = EVehicles[idx].DCCharge[(startIdx + 1).toFixed(0)]; // the loading speed at the end of the interval
    let interCharge = ((upperCharge - lowerCharge) / 10) * interSoc; // calculates the value which needs to be deducted from the upper load speed as it is not constant in the interval
    let speed = Math.min(upperCharge - interCharge, DcChargers[dcmax].power); // the charging speed for this iteration
    let newSoc = soc.abs + speed / 60; // the new state of charge after the time period (minute)

    soc = {
      abs: newSoc,
      rel: (newSoc / EVehicles[idx].batterySize) * 100,
      speed: speed,
      min: ++min,
    };
    chargeProcess.push(soc);
  }
  return chargeProcess;
}

// *** Function to calculate the average Charging Speed for the given interval

function calculateAvgChargingSpeed(chargeProcess) {
  let sum = 0;

  for (let i = 0; i < chargeProcess.length; i++) {
    sum = sum + chargeProcess[i].speed;
  }
  return sum / chargeProcess.length;
}

// *********************
// *** Main function ***
// *********************

function EVTools() {
  ChartJS.register(CategoryScale);
  const chartGridColor = "#504848";
  const chartTicksColor = "darkgrey";
  const data1Color = "rgb(75, 192, 192)";
  const data2Color = "#695408";
  const carlist = carList();
  const cars = carlist.map((car) => <option key={car.id}>{car.name}</option>);
  const dcChargerList = DcChargers.map((dcc) => (
    <option key={dcc.id}>{dcc.type}</option>
  ));
  const acChargerList = AcChargers.map((acc) => (
    <option key={acc.id}>{acc.type}</option>
  ));
  const [selectedCar, setSelectedCar] = useState(0);
  const [dcCharger, setDcCharger] = useState(DcChargers[0].id);
  const [acCharger, setAcCharger] = useState(AcChargers[0].id);
  const [startSoc, setStartSoc] = useState(10);
  const [targetSoc, setTargetSoc] = useState(80);
  let chargeData = chargingProcess(selectedCar, startSoc, targetSoc, dcCharger);

  return (
    <div className="tools">
      <div className="subhead">E-Vehicle Tools</div>

      <div className="evcar">
        <div className="sub3head">
          Overall Charging time - from {startSoc}% to {targetSoc}%
        </div>
        <table>
          <tbody>
            <tr>
              <td className="theader" width="100">
                Brand
              </td>
              <td width="150">{EVehicles[selectedCar].brand}</td>
            </tr>
            <tr>
              <td className="theader">Model</td>
              <td>{EVehicles[selectedCar].model}</td>
            </tr>
            <tr>
              <td className="theader">Model year</td>
              <td>{EVehicles[selectedCar].modelYear}</td>
            </tr>
            <tr>
              <td className="theader">Variant</td>
              <td>{EVehicles[selectedCar].variant}</td>
            </tr>
            <tr>
              <td className="theader">Battery Size</td>
              <td>{EVehicles[selectedCar].batterySize} kWh</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="evcharge">
        <div className="resultsTable">
          <table>
            <tbody>
              <tr>
                <td className="theader-large">Duration DC</td>
                <td className="tcont-large">
                  {calcDuration(selectedCar, startSoc, targetSoc, dcCharger)}
                </td>
              </tr>
              <tr>
                <td className="theader">loaded capacity</td>
                <td className="tcont-medium">
                  {(
                    ((targetSoc - startSoc) / 100) *
                    EVehicles[selectedCar].batterySize
                  ).toFixed(1)}{" "}
                  kWh
                </td>
              </tr>
              <tr>
                <td className="theader">avg. charging speed</td>
                <td className="tcont-medium">
                  {calculateAvgChargingSpeed(chargeData).toFixed(0)} kW
                </td>
              </tr>
              <tr>
                <td className="theader">Duration AC</td>
                <td className="tcont-medium">
                  {calcAcDuration(selectedCar, startSoc, targetSoc, acCharger)}{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="evgraph">
        <div className="sub3head">DC Charging Process</div>
        <div className="chart-container">
          <Line
            className="chart"
            data={{
              labels: chargeData.map((data) => data.min),
              datasets: [
                {
                  label: selectDataType("abs"),
                  data: chargeData.map((data) => data.abs),
                  backgroundColor: "green",
                  showLine: true,
                  fill: false,
                  borderColor: data1Color,
                  tension: 0.3,
                  yAxisID: "abs",
                },
                {
                  label: selectDataType("rel"),
                  data: chargeData.map((data) => data.rel),
                  backgroundColor: "green",
                  showLine: true,
                  fill: false,
                  borderColor: data2Color,
                  tension: 0.3,
                  yAxisID: "rel",
                },
              ],
            }}
            options={{
              responsive: true,
              scales: {
                x: {
                  label: "test",
                  ticks: {
                    color: chartTicksColor,
                  },
                  border: {
                    display: true,
                    color: chartGridColor,
                  },
                  grid: {
                    display: true,
                    drawOnChartArea: true,
                    color: chartGridColor,
                    drawTicks: true,
                  },
                },
                abs: {
                  min: 0,
                  max: EVehicles[selectedCar].batterySize,
                  ticks: {
                    callback: function (value, index, ticks) {
                      return value + " kWh";
                    },
                    padding: 10,
                    color: data1Color,
                  },
                  border: {
                    display: true,
                    color: chartGridColor,
                  },
                  grid: {
                    display: true,
                    drawOnChartArea: true,
                    color: data1Color,
                    drawTicks: true,
                  },
                },
                rel: {
                  position: "right",
                  min: 0,
                  max: 100,
                  ticks: {
                    callback: function (value, index, ticks) {
                      return value + " %";
                    },
                    padding: 10,
                    color: data2Color,
                  },
                  border: {
                    display: true,
                    color: chartGridColor,
                  },
                  grid: {
                    display: true,
                    drawOnChartArea: false,
                    color: data2Color,
                    drawTicks: true,
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: "Charging over time",
                  color: "white",
                  position: "top",
                  font: function (context) {
                    var width = context.chart.width;
                    var size = Math.round(width / 30);
                    size = size > 22 ? 22 : size; 
                    return {
                      weight: "bold",
                      size: size,
                    };
                  },
                },
                subtitle: {
                  display: true,
                  text: "absolute charge of vehicle",
                  position: "left",
                  color: "white",
                  font: function(context) {
                    var width = context.chart.width;
                    var size = Math.round(width / 32);
                    size = size > 18 ? 18 : size; 
                    return {
                        size: size
                    };
                },
                },
                legend: {
                  display: false,
                  position: "bottom",
                },
              },
            }}
          />
        </div>
      </div>

      <div className="evinput">
        <div className="evsub3head">Select your EV</div>
        <select
          className="cars"
          name="selectedCar"
          onChange={(e) => setSelectedCar(e.target.selectedIndex)}
        >
          {cars}
        </select>
        <div className="evsub3head">State of Charge</div>
        <table>
          <tbody>
            <tr>
              <td>Start value (%)</td>
              <td>
                <input
                  type="number"
                  min="0"
                  max={targetSoc}
                  className="socinput"
                  name="startsoc"
                  defaultValue={startSoc}
                  onChange={(e) => setStartSoc(e.target.value)}
                ></input>
              </td>
            </tr>
            <tr>
              <td>Target value (%)</td>
              <td>
                <input
                  type="number"
                  min={startSoc}
                  max="99"
                  className="socinput"
                  name="targetsoc"
                  defaultValue={targetSoc}
                  onChange={(e) => setTargetSoc(e.target.value)}
                ></input>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <div className="evsub3head">Charging station</div>
        <table>
          <tbody>
            <tr>
              <td className="charger">DC Charger</td>
              <td>
                <select
                  className="charger"
                  name="DcCharger"
                  onChange={(e) => setDcCharger(e.target.selectedIndex)}
                >
                  {dcChargerList}
                </select>
              </td>
            </tr>
            <tr>
              <td>AC Charger</td>
              <td>
                <select
                  className="charger"
                  name="AcCharger"
                  onChange={(e) => setAcCharger(e.target.selectedIndex)}
                >
                  {acChargerList}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="notemedium">
          All calculations are based on European <br />
          standards for charging stations.
        </div>
      </div>
    </div>
  );
}

export default EVTools;
