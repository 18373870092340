import React, { useMemo, useState, useEffect } from "react";
import { EVehicles } from "../components/EVehicles";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  flexRender,
  useReactTable,
} from "@tanstack/react-table";
import { COLUMNS } from "../components/EVTableColumns";

function EVCompare() {
  const data = useMemo(() => EVehicles, []);
  const columns = useMemo(() => COLUMNS, []);
  const [electricalChar, setElectricalChar] = useState(true);
  const [physicalChar, setPhysicalChar] = useState(false);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const table = useReactTable({
    columns,
    data,
    state: {
      sorting,
      columnVisibility,
      pagination: { pageSize: 55, pageIndex: 0 },
    },
    //initialState: { pagination: {pageSize: 15 }},
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    /* debugTable: true,
    debugHeaders: true,
    debugColumns: true, */
    defaultColumn: {
      size: 100, //starting column size
      minSize: 50, //enforced during column resizing
      maxSize: 100, //enforced during column resizing
    },
  });
  useEffect(
    () =>
      table.setColumnVisibility({
        length: false,
        width: false,
        trunkVol: false,
        frunkVol: false,
        turnCircle: false,
        maxLoad: false,
        trailerMax: false,
      }),
    []
  );

  const setToElectricalChar = () => {
    setElectricalChar(true);
    setPhysicalChar(false);
    for (let i = 4; i < 10; i++) {
      if (!table.getAllLeafColumns()[i].getIsVisible())
        table.getAllLeafColumns()[i].toggleVisibility();
    }
    for (let i = 10; i < 17; i++) {
      if (table.getAllLeafColumns()[i].getIsVisible())
        table.getAllLeafColumns()[i].toggleVisibility();
    }
  };
  const setToPhysicalChar = () => {
    setElectricalChar(false);
    setPhysicalChar(true);
    for (let i = 4; i < 10; i++) {
      if (table.getAllLeafColumns()[i].getIsVisible())
        table.getAllLeafColumns()[i].toggleVisibility();
    }
    for (let i = 10; i < 17; i++) {
      if (!table.getAllLeafColumns()[i].getIsVisible())
        table.getAllLeafColumns()[i].toggleVisibility();
    }
  };

  const RadioButton = ({ label, value, onChange, name, className }) => {
    return (
      <label className={className}>
        <input type="radio" checked={value} onChange={onChange} name={name} />
        {label}
      </label>
    );
  };

  return (
    <div className="evcompare">
      <div className="subhead whitetext">E-Vehicle Comparison</div>
      <div className="evcdescription">
        This is a brief comparisson of selected electrical cars in Europe.
        <br />
        <br />
        You can select to compare the electrical and the physical
        characteristics of the vehicles. <br />
        <br />
        Notable is the realistic reach, which is an estimation based on the
        rather unrealistic WLTP range, an correction factor for it and then
        calculated to the practical usable battery level of 90% start and 10%
        remaining capacity.
        <br />
        <br />
        Battery sizes reflect the usable capacity which might vary from the
        advertised battery size. This value is typically listed in the technical
        datasheet. <br />
        <br /> The power is the peak power. The continous usable power is a
        smaller fraction and can vary a lot between EVs. <br />
        For example, the Polestar 2 long range single motor 2023 has 170kW peak
        power but only 80kW, 47% of peak, continous power. <br />
        Comparing with the Citroën e-C4 136 has 100kW peak and 57kW (57%)
        continous power. So the later has relatively more continous power.
        <br /> Variation ranges from 18% of peak with Hyundai Kona 150 over 30%
        of Tesla S Performance to 57% of Citroën e-C4 with an average of 45%
        cross the cars I had looked at. I might add that detail to another
        column at some time.
        <br />
        There is few information available, for how long the peak power can be
        consumed, but it will not be more than 30 minutes. Compared to a
        combustion engine, this sounds odd, but also there, the maximum power is
        only available at a certain rpm and typically in a range, you do not
        want (and need) to run permanently. So the peak power is mainly used for
        acceleration while the permanent power is responsible for the speed you
        can run on the motorway.
      </div>
      <div className="tablearea">
        <div className="radioContainer">
          <div className="radio">
            Characteristics to display:
            <RadioButton
              label="electrical"
              value={electricalChar}
              onChange={setToElectricalChar}
              name="rate"
              className="radio"
            />
            <RadioButton
              label="physical"
              value={physicalChar}
              onChange={setToPhysicalChar}
              name="duration"
              className="radio"
            />
            <br/><br/>Click on the header item to sort the table.
          </div>
          <br />
        </div>
        <br />
        <table className="evc">
          <thead className="evc">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr className="evc" key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      className="evc"
                      colSpan={header.colSpan}
                      key={header.id}
                      style={{ width: `${header.getSize()}px` }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "curser-point select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: " ▲",
                            desc: " ▼",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody className="evc">
            {table.getRowModel().rows.map((row) => {
              //prepareRow(row);
              return (
                <tr className="evc" key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td className="evc" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default EVCompare;
