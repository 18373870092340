import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  // Route,
  // ‚Link,
  Outlet,
  // createRoutesFromElements,
} from "react-router-dom";
// import About from "./routes/About";
import EVTools from "./routes/EVTools";
import EVCompare from "./routes/EVCompare.tsx";
import Timer from "./routes/Timer";
import Home from "./routes/Home";
import Imprint from "./routes/Imprint";
import Tilgung from "./routes/Tilgungsplan";
import Template from "./routes/Template.js";
import Navbar from "./components/Navbar";
import "./App.css";

const AppLayout = () => (
  <>
    <Navbar />
    <Outlet />
  </>
);

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "evtools",
        element: <EVTools />,
      },
      {
        path: "evcompare",
        element: <EVCompare />,
      },
      {
        path: "timer",
        element: <Timer />,
      },
      /* {
        path: "about",
        element: <About />,
      }, */
      {
        path: "imprint",
        element: <Imprint />,
      },
      {
        path: "tilgung",
        element: <Tilgung />,
      },
      {
        path: "template",
        element: <Template />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
