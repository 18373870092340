export const DcChargers = [
    {
        id: 0,
        type: "HPC (350kW)",
        power: "350"
    },
    {
        id: 1,
        type: "HPC (300kW)",
        power: "300"
    },
    {
        id: 2,
        type: "Tesla Supercharger (250kW)",
        power: "250"
    },
    {
        id: 3,
        type: "HPC (150kW)",
        power: "150"
    },
    {
        id: 4,
        type: "Tesla Supercharger (135kW)",
        power: "135"
    },
    {
        id: 5,
        type: "CCS Combo 2 (100kW)",
        power: "100"
    },
    {
        id: 6,
        type: "CCS Combo 2/ChAdeMo (50kW)",
        power: "50"
    }
]