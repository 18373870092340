import React, { useState } from "react";
import { Chart as ChartJS } from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Line } from "react-chartjs-2";

function leadingZero(element) {
  if (element < 10) return "0" + element;
  else return element;
}

function monthYear(offset) {
  let d = new Date();
  d.setMonth(d.getMonth() + offset);
  let my =
    "01." + leadingZero(Number(d.getMonth() + 1)) + "." + d.getFullYear();
  return my;
}

function months2years(months) {
  let years = Math.floor(months / 12);
  let result = "";
  months = months - years * 12;
  if (months === 0) {
    result = years + " Jahre ";
  } else result = years + " Jahre " + months + " Monate";
  return result;
}

// subfunction to calculate by duration

function byDuration(value, interest, duration) {
  let result = [];
  let lowerLimit = value / duration;
  let upperLimit =
    (value * Math.pow(1 + interest / 100, duration / 12)) / duration;
  let startRate = Math.ceil(
    (lowerLimit + (upperLimit - lowerLimit) / 2) * 1.01
  ); // wanted to use full numbers, last factor is to lift it a bit further
  //above the limit in order to make sure we start with a valid iteration

  do {
    startRate--;
    result = byRate(value, interest, startRate);
  } while (result.length < Number(duration));

  return result;
}

// subfunction to calculate by rate

function byRate(value, interest, rate) {
  let i = {
    month: "0",
    value: value,
    interestAbs: null,
    redemptionAbs: null,
    rate,
    cumInterest: null,
  };
  let result = [];
  let month = 0;
  let interestAbs = 0;
  let redemptionAbs = 0;
  let newValue = value;
  let cumInterest = 0;

  while (newValue > 0) {
    month++;
    if (month > 1200) {
      i = {
        month: ">1200",
        value: "",
        interestAbs: "",
        redemptionAbs: "",
        rate: "",
        cumInterest: "",
      };
      console.log(
        "Error: rate too low, couldn't reach target after 1200 iterations (100 years)"
      );
      break;
    }

    interestAbs = (newValue * interest) / 100 / 12;
    redemptionAbs = rate - interestAbs;
    cumInterest = cumInterest + interestAbs;
    if (newValue - redemptionAbs < 0) {
      redemptionAbs = newValue;
      rate = redemptionAbs + interestAbs;
    }

    i = {
      month,
      value: newValue,
      interestAbs: interestAbs,
      redemptionAbs: redemptionAbs,
      rate,
      cumInterest,
    };
    result.push(i);
    newValue = newValue - redemptionAbs;
  }
  return result;
}

// function to calculate the plan on a monthly base

function repayMonthly(value, interest, rate, duration, rateSelected) {
  let result = [];
  if (!rateSelected)
    if (duration === 0) {
      return result.push({
        month: "0",
        value,
        interestAbs: "0",
        redemptionAbs: "0",
        rate,
      });
    } else {
      result = byDuration(value, interest, duration);
    }
  else {
    result = byRate(value, interest, rate);
  }
  return result;
}

function createDurations() {
  let durations = [];
  for (let i = 1; i <= 10; i++) {
    let d = { value: i * 12, label: i * 12 };
    console.log(d);
    durations.push(d);
  }
  return durations;
}

// *********************
// *** Main function ***
// *********************

function Tilgung() {
  ChartJS.register(CategoryScale);
  const chartGridColor = "darkgrey";
  const chartTicksColor = "darkgrey";
  const data1Color = "rgb(187, 194, 239)";
  const data2Color = "lightgreen";
  const data3Color = "pink";
  const [rate, setRate] = useState(300);
  const [duration, setDuration] = useState(24);
  const [interest, setInterest] = useState(3);
  const [value, setValue] = useState(10000);

  const [fixedDuration, setFixedDuration] = React.useState(false);
  const [fixedRate, setFixedRate] = React.useState(true);
  let repayPlan = repayMonthly(value, interest, rate, duration, fixedRate);
  let durations = createDurations();
  const selectorDurations = durations.map((d) => (
    <option key={d.value}>{d.label}</option>
  ));

  const setToFixedRate = () => {
    setFixedRate(true);
    setFixedDuration(false);
    console.log("Duration: " + fixedDuration, "-  Rate:" + fixedRate);
  };
  const setToFixedDuration = () => {
    setFixedRate(false);
    setFixedDuration(true);
    console.log("Duration: " + fixedDuration, "-  Rate:" + fixedRate);
  };

  const RadioButton = ({ label, value, onChange, name, className }) => {
    return (
      <label className={className}>
        <input type="radio" checked={value} onChange={onChange} name={name} />
        {label}
      </label>
    );
  };

  return (
    <div className="tilgung">
      <div className="subhead">Tilgungsplan</div>

      {/* ### Entryform on left side column ### */}
      <div className="tilgentries">
        <table>
          <tbody>
            <tr>
              <td width="140">Kreditbetrag (EUR)</td>
              <td>
                <input
                  type="number"
                  min="1"
                  className="tilginput"
                  name="value"
                  defaultValue="10000"
                  onChange={(e) => setValue(e.target.value)}
                ></input>
              </td>
            </tr>
            <tr>
              <td>Zinssatz (%)</td>
              <td>
                <input
                  type="number"
                  min="0"
                  max="99"
                  className="tilginput"
                  name="interest"
                  defaultValue="3.0"
                  onChange={(e) => setInterest(e.target.value)}
                ></input>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="sub3head extra-margin">Art der Berechnung</div>
        <div>
          <RadioButton
            label="feste Rate"
            value={fixedRate}
            onChange={setToFixedRate}
            name="rate"
            className="tradio"
          />
        </div>
        <div>
          <RadioButton
            label="feste Dauer"
            value={fixedDuration}
            onChange={setToFixedDuration}
            name="duration"
            className="tradio"
          />
        </div>
        <br />
        <table>
          <tbody>
            <tr>
              <td width="140">Monatsrate (EUR)</td>
              <td>
                <input
                  type="number"
                  min="1"
                  className="tilginput"
                  name="rate"
                  defaultValue="300"
                  onChange={(e) => setRate(e.target.value)}
                  disabled={!fixedRate}
                ></input>
              </td>
            </tr>
            <tr>
              <td>Dauer (Monate)</td>
              <td>
                <select
                  name="duration"
                  className="tilginput"
                  onChange={(e) => setDuration(e.target.value)}
                  disabled={!fixedDuration}
                  defaultValue="24"
                >
                  {selectorDurations}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <div className="notemedium">
          Für die Berechnung übernehme ich keine Gewährleistung.
        </div>
      </div>

      <div className="tilgbasics">
        <div className="sub3head">Tilgungsplan</div>
        <table>
          <tbody>
            <tr>
              <td className="tilgsummaryheader">Kreditsumme</td>
              <td>
                {Number(repayPlan[0].value).toLocaleString("de-DE", {
                  style: "currency",
                  currency: "EUR",
                })}
              </td>
            </tr>
            <tr>
              <td className="tilgsummaryheader">nom. Zinssatz</td>
              <td>{Number(interest).toLocaleString("de-DE")}% p.a.</td>
            </tr>
            <tr>
              <td className="tilgsummaryheader">mtl. Rate</td>
              <td>
                {Number(repayPlan[0].rate).toLocaleString("de-DE", {
                  style: "currency",
                  currency: "EUR",
                })}
              </td>
            </tr>
            <tr>
              <td className="tilgsummaryheader">letzte Rate</td>
              <td>
                {Number(repayPlan[repayPlan.length - 1].rate).toLocaleString(
                  "de-DE",
                  {
                    style: "currency",
                    currency: "EUR",
                  }
                )}
              </td>
            </tr>

            <tr>
              <td className="tilgsummaryheader">kum. Zinsen</td>
              <td>
                {Number(
                  repayPlan[repayPlan.length - 1].cumInterest
                ).toLocaleString("de-DE", {
                  style: "currency",
                  currency: "EUR",
                })}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="tilgduration">
        <div className="resultsTable">   { /* classname not used */}
          <table>
            <tbody>
              <tr>
                <td className="tilgsummaryheader">Startdatum</td>
                <td>{monthYear(1)}</td>
              </tr>
              <tr>
                <td className="tilgsummaryheader">Dauer</td>
                <td>
                  {repayPlan.length} Monate ({months2years(repayPlan.length)})
                </td>
              </tr>
              <tr>
                <td className="tilgsummaryheader">Enddatum</td>
                <td>{monthYear(repayPlan.length)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="tilgprocess">
        <div className="sub3head">Tilgungsprozess</div>

        <table>
          <thead>
            <tr>
              <th className="tilgtheader">Monat</th>
              <th className="tilgtheader" width="150">
                Betrag
              </th>
              <th className="tilgtheader" width="150">
                Zinsen
              </th>
              <th className="tilgtheader" width="150">
                Tilgung
              </th>
              <th className="tilgtheader" width="150">
                Rate
              </th>
            </tr>
          </thead>

          <tbody className="tilgtable">
            {repayPlan.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.month}</td>
                  <td>
                    {Number(item.value).toLocaleString("de-DE", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </td>
                  <td>
                    {Number(item.interestAbs).toLocaleString("de-DE", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </td>
                  <td>
                    {Number(item.redemptionAbs).toLocaleString("de-DE", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </td>
                  <td>
                    {Number(item.rate).toLocaleString("de-DE", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="tilggraph">
        <div className="chartdesc">
          Der folgende Graph zeigt die Entwicklung der Tilgung, Zinsen und
          Restwert. Durch das Klicken in der Legende oberhalb des Graphen,
          lassen sich einzelne Elemente ein- und ausschalten.
        </div>
        <div className="chart-container">
          <Line
            className="chart"
            data={{
              labels: repayPlan.map((data) => data.month),
              datasets: [
                {
                  label: "Tilgung",
                  data: repayPlan.map((data) => data.redemptionAbs),
                  backgroundColor: "#272a3a",
                  showLine: true,
                  fill: false,
                  borderColor: data1Color,
                  tension: 0.3,
                  yAxisID: "abs",
                },
                {
                  label: "Zinsen",
                  data: repayPlan.map((data) => data.interestAbs),
                  backgroundColor: "#272a3a",
                  showLine: true,
                  fill: false,
                  borderColor: data2Color,
                  tension: 0.3,
                  yAxisID: "abs",
                },
                {
                  label: "Restwert",
                  data: repayPlan.map((data) => data.value),
                  backgroundColor: "#272a3a",
                  showLine: true,
                  fill: false,
                  borderColor: data3Color,
                  tension: 0.3,
                  yAxisID: "abs",
                },
              ],
            }}
            options={{
              responsive: true,
              scales: {
                x: {
                  label: "Monat",
                  ticks: {
                    color: chartTicksColor,
                  },
                  border: {
                    display: true,
                    color: chartGridColor,
                  },
                  grid: {
                    display: true,
                    drawOnChartArea: true,
                    color: chartGridColor,
                    drawTicks: true,
                  },
                },
                abs: {
                  min: 0,
                  ticks: {
                    callback: function (value, index, ticks) {
                      return value + " EUR";
                    },
                    padding: 20,
                    color: data1Color,
                  },
                  border: {
                    display: false,
                    color: "white",
                  },
                  grid: {
                    display: true,
                    drawOnChartArea: true,
                    color: "white",
                    drawTicks: true,
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: "Tilgung",
                  color: "white",
                  position: "top",
                  font: {
                    size: "20",
                  },
                },
                subtitle: {
                  display: true,
                  text: "Betrag",
                  position: "left",
                  color: "white",
                  font: {
                    size: "15",
                  },
                },
                legend: {
                  display: true,
                  position: "top",
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Tilgung;
